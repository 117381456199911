export const getCurrentDate = () => {
  const env = process.env.GATSBY_DD_ENV;
  if (env === 'prod') {
    return new Date();
  }

  if (typeof window !== 'undefined') {
    const sessionDate = sessionStorage.getItem('mock-date');

    if (sessionDate) {
      return new Date(sessionDate);
    }
  }

  return new Date();
};
