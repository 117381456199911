import { ProductName, Promo } from '@entities/enums';
import { PromoConfig, PromoName } from '@entities/types';
import {
  absThreeYearOnePoundOfferCodes,
  energyRefixFreeAbsPromoCodes,
  OSPFreeAbsPromoCodes,
  q1HalfPriceAbsPromoCodes,
  q2HalfPriceAbsPromoCodes,
} from '@constants/promotions';
import PromoService from '@services/PromoService';
import {
  q3FreeMonthsInsuranceFootnotes,
  beyondPromoFootnotes,
  energyRefixFreeAbsFootnote,
  OSPFreeAbsFootnote,
  absThreeYearOnePoundOfferFootnote,
  buildGenericOfferFootnote,
} from '@constants/footnotes';
import { generateSequentialPromocode } from '@utils/generateSequentialPromocode';
import { isBeyondAtom, isHomeRecoverAtom, store } from '@src/store/store';

export const directPromos: PromoConfig[] = [
  {
    order: 1,
    validate: () => {
      const isBeyond = store.get(isBeyondAtom);
      const isHomeRecover = store.get(isHomeRecoverAtom);
      const isFlashSale = PromoService.getActiveIndirectPromos().some(
        (promo) => promo.name === PromoName.Q1202512MonthsHalfPrice
      );
      return !isFlashSale && !isBeyond && !isHomeRecover;
    },
    name: PromoName.Q120258MonthsHalfPrice,
    startDate: new Date('2025-01-07T00:00:00'),
    endDate: new Date('2025-03-20T23:59:59'),
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    footnote: buildGenericOfferFootnote({
      endDate: new Date('2025-03-21T00:00:00.000'),
      id: 'q1-2025-8months-halfprice',
    }),
    promoOptions: {
      type: 'FIRSTMONTHSDISCOUNT',
      duration: 8,
      discountPercentage: 50,
      codes: [...generateSequentialPromocode(Promo.Q1SAVE, 45)],
    },
  },
  {
    order: 1,
    validate: () => {
      const isBeyond = store.get(isBeyondAtom);
      const isHomeRecover = store.get(isHomeRecoverAtom);
      const isFlashSale = PromoService.getActiveIndirectPromos().some(
        (promo) =>
          promo.name === PromoName.FlashSaleSixMonthsFree ||
          promo.name === PromoName.BlackFridaySixMonthsFree
      );
      return !isFlashSale && !isBeyond && !isHomeRecover;
    },
    endDate: new Date('2024-12-13T00:00:00'),
    startDate: new Date('2024-09-17T00:00Z'),
    name: PromoName.TargetedOfferFourMonthsFree,
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    footnote: q3FreeMonthsInsuranceFootnotes.q3FreeMonthsInsurance,
    promoOptions: {
      type: 'FIRSTMONTHSFREE',
      duration: 4,
      codes: [...generateSequentialPromocode(Promo.Q4SAVE, 150), Promo.Q424BEYOND],
    },
  },
  {
    order: 1,
    validate: () => {
      const isBeyond = store.get(isBeyondAtom);
      const isHomeRecover = store.get(isHomeRecoverAtom);
      const isFlashSale = PromoService.getActiveIndirectPromos().some(
        (promo) =>
          promo.name === PromoName.FlashSaleSixMonthsFree ||
          promo.name === PromoName.BlackFridaySixMonthsFree
      );
      return isFlashSale && !isBeyond && !isHomeRecover;
    },
    endDate: new Date('2024-12-13T00:00:00'),
    startDate: new Date('2024-09-17T00:00Z'),
    name: PromoName.TargetedOfferSixMonthsFree,
    footnote: q3FreeMonthsInsuranceFootnotes.q3FreeMonthsInsuranceFlashSale,
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    promoOptions: {
      type: 'FIRSTMONTHSFREE',
      duration: 6,
      codes: [...generateSequentialPromocode(Promo.Q4SAVE, 150), Promo.Q424BEYOND],
    },
  },
  {
    order: 2,
    startDate: new Date('2024-01-09T00:00Z'),
    name: PromoName.Q1AbsDirectOffer,
    appliesTo: [ProductName.ABS],
    promoOptions: {
      type: 'PERCENTDISCOUNT',
      percentage: 50,
      codes: q1HalfPriceAbsPromoCodes,
    },
  },
  {
    order: 3,
    name: PromoName.AbsBeyond,
    startDate: new Date('2023-11-30T00:00:00'),
    endDate: new Date('2024-04-16T00:00:00'),
    appliesTo: [ProductName.ABS],
    footnote: beyondPromoFootnotes?.beyondPromo,
    promoOptions: {
      type: 'PERCENTDISCOUNT',
      percentage: 71.42,
      codes: [Promo.BEYOND],
    },
  },
  {
    order: 4,
    name: PromoName.EnergyRefixFreeABS,
    startDate: new Date('2024-08-28T00:00:00'),
    appliesTo: [ProductName.ABS],
    footnote: energyRefixFreeAbsFootnote.energyRefixFreeAbs,
    promoOptions: {
      type: 'FREE',
      codes: energyRefixFreeAbsPromoCodes,
    },
  },
  {
    order: 5,
    name: PromoName.BeyondFreeAbs,
    startDate: new Date('2024-04-16T00:00:00'),
    appliesTo: [ProductName.ABS],
    footnote: beyondPromoFootnotes?.beyondFreeAbs,
    promoOptions: {
      type: 'FREE',
      codes: q2HalfPriceAbsPromoCodes,
    },
  },
  {
    order: 5,
    name: PromoName.Beyond12MonthsHalfPrice,
    validate: () => {
      const isBeyond = store.get(isBeyondAtom);
      return isBeyond === true;
    },
    startDate: new Date('2024-05-14T00:00:00'),
    endDate: new Date('2024-09-23T00:00:00'),
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    footnote: beyondPromoFootnotes?.beyond12MonthsHalfPrice,
    promoOptions: {
      type: 'FIRSTMONTHSDISCOUNT',
      duration: 12,
      discountPercentage: 50,
      codes: [Promo.BEYOND12HP],
    },
  },
  {
    order: 5,
    name: PromoName.OSPFreeABS,
    startDate: new Date('2024-10-21T00:00:00'),
    appliesTo: [ProductName.ABS],
    footnote: OSPFreeAbsFootnote?.OSPFreeAbs,
    promoOptions: {
      type: 'FREE',
      codes: OSPFreeAbsPromoCodes,
    },
  },
  {
    order: 5,
    name: PromoName.AbsThreeYearOnePoundOffer,
    startDate: new Date('2024-11-11T00:00:00'),
    appliesTo: [ProductName.ABS],
    footnote: absThreeYearOnePoundOfferFootnote?.absThreeYearOnePound,
    promoOptions: {
      type: 'FIXEDTERMFIXEDPRICE',
      duration: 36,
      value: 1,
      codes: absThreeYearOnePoundOfferCodes,
    },
  },
];
