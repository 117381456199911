import React, { useEffect, useState } from 'react';
import {
  createInstance,
  OptimizelyProvider as OptimizelyProviderOriginal,
} from '@optimizely/react-sdk';
import { datadogLogs } from '@datadog/browser-logs';
import { LogLevel } from '@optimizely/js-sdk-logging';
import { useAtomValue } from 'jotai';

import { useLocalStorage } from '@contexts/LocalStorageContext';
import { homePlanStoreAtom } from '@src/store/store';

export interface DynamicUserProps {
  coverType: string;
  excessLevel: number;
  accountId: string;
  testUser: boolean;
}

export enum ExperimentKeys {
  blackFridayBanner = 'bfbannertype',
}

const optimizely =
  typeof window === 'undefined'
    ? null
    : createInstance({
        sdkKey: process.env.OPTIMIZELY_SDK_KEY, // TODO: Find where this should be set
        logLevel: LogLevel.WARNING,
      });

const staticUserAttributes = {
  env: process.env.OPTIMIZELY_ENV_ATTRIBUTE ?? 'not-set',
};

export const OptimizelyProvider: React.FC = ({ children }) => {
  const localStore = useLocalStorage();
  const localStorageStore = localStore.load();
  const jotaiStore = useAtomValue(homePlanStoreAtom);
  // Product would rather the experience be consistent for a given browser, than for a given OVO accountId
  // We could use uuid here, but Math.random is more than sufficient and keeps the bundle size down.
  const optimizelyUserId = localStorageStore.optimizelyUserId ?? `id-${Math.random()}`;

  const testUser = localStorageStore.testUser ? localStorageStore.testUser : false;

  const [dynamicProps, setDynamicProps] = useState<DynamicUserProps>({
    coverType: 'Essential',
    excessLevel: 0,
    accountId: '',
    testUser,
  });

  useEffect(() => {
    localStore.save({ optimizelyUserId, testUser });
    datadogLogs.setGlobalContextProperty('optimizelyUserId', optimizelyUserId);
  }, [optimizelyUserId, testUser, localStore]);

  useEffect(() => {
    if (!jotaiStore.selectedCoverType) {
      return;
    }
    setDynamicProps((state) => {
      return {
        ...state,
        coverType: jotaiStore.selectedCoverType as string,
        excessLevel: jotaiStore.excess ?? 0,
        accountId: jotaiStore.accountId ?? '',
        testUser,
      };
    });
  }, [jotaiStore.selectedCoverType, jotaiStore.excess, jotaiStore.accountId, testUser]);

  return (
    <OptimizelyProviderOriginal
      isServerSide={typeof window === 'undefined'}
      optimizely={optimizely!}
      user={{
        id: optimizelyUserId,
        attributes: {
          ...staticUserAttributes,
          ...dynamicProps,
        },
      }}
      timeout={500}
    >
      {children}
    </OptimizelyProviderOriginal>
  );
};
