import { Provider } from 'jotai';
import { ReactNode, useEffect } from 'react';

import { resetStore, store } from './store';

export const StoreProvider = ({ children }: { children?: ReactNode }) => {
  useEffect(() => {
    return () => {
      resetStore();
    };
  }, []);

  return <Provider store={store}>{children}</Provider>;
};
