import { useQuery } from 'react-query';

import AuthService from '@services/AuthService';

export const useAuth = () => {
  const {
    data: user,
    isLoading: loading,
    isFetched: fetched,
    error,
  } = useQuery({
    staleTime: 60000,
    cacheTime: 60000,
    queryKey: ['useAuth'],
    queryFn: AuthService.getCurrentlyLoggedInUser,
  });

  return {
    user,
    loading,
    error,
    fetched,
  };
};
