import { ProductName, Promo } from '@entities/enums';
import { PromoConfig, PromoName } from '@entities/types';
import { q1HalfPriceAbsPromoCodes, q2HalfPriceAbsPromoCodes } from '@constants/promotions';
import {
  beyondPromoFootnotes,
  q2HalfPriceAbsFootnotes,
  q3FreeMonthsInsuranceFootnotes,
} from '@constants/footnotes';
import { generateSequentialPromocode } from '@utils/generateSequentialPromocode';
import PromoService from '@services/PromoService';
import { isBeyondAtom, isHomeRecoverAtom, store, tariffNameAtom } from '@src/store/store';

export const autoTestIndirectPromos: PromoConfig[] = [
  {
    order: 100,
    name: PromoName.Q2AbsOfferAutoTest,
    endDate: new Date('2023-12-31T23:59:59'),
    appliesTo: [ProductName.ABS],
    footnote: q2HalfPriceAbsFootnotes?.q2HalfPriceAbs,
    promoOptions: {
      type: 'PERCENTDISCOUNT',
      percentage: 50,
    },
  },
  {
    order: 5,
    name: PromoName.HomeRecoverFreeRepairAutoTest,
    appliesTo: [ProductName.COMPLETE],
    addon: true,
    endDate: new Date('2023-12-31T23:59:59'),
    promoOptions: {
      type: 'FREEREPAIR',
    },
    validate: () => {
      const isHomeRecover = store.get(isHomeRecoverAtom);
      const tariffName = store.get(tariffNameAtom);
      return isHomeRecover === true && tariffName === undefined;
    },
  },
  {
    order: 1,
    name: PromoName.FlashSaleSixMonthsFreeAutoTest,
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    endDate: new Date('2023-12-31T23:59:59'),
    footnote: q3FreeMonthsInsuranceFootnotes.q3FreeMonthsInsuranceFlashSale,
    promoOptions: {
      type: 'FIRSTMONTHSFREE',
      duration: 6,
    },
    validate: () => {
      const isBeyond = store.get(isBeyondAtom);

      return !isBeyond;
    },
  },
];

export const autoTestDirectPromos: PromoConfig[] = [
  {
    order: 2,
    name: PromoName.Q1AbsDirectOfferAutoTest,
    appliesTo: [ProductName.ABS],
    endDate: new Date('2023-12-31T23:59:59'),
    promoOptions: {
      type: 'PERCENTDISCOUNT',
      percentage: 50,
      codes: q1HalfPriceAbsPromoCodes,
    },
  },
  {
    order: 5,
    name: PromoName.BeyondFreeAbsAutoTest,
    appliesTo: [ProductName.ABS],
    endDate: new Date('2023-12-31T23:59:59'),
    footnote: beyondPromoFootnotes?.beyondFreeAbs,
    promoOptions: {
      type: 'FREE',
      codes: q2HalfPriceAbsPromoCodes,
    },
  },
  {
    order: 6,
    name: PromoName.Beyond12MonthsHalfPriceAutoTest,
    validate: () => {
      const isBeyond = store.get(isBeyondAtom);
      return isBeyond === true;
    },
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    endDate: new Date('2023-12-31T23:59:59'),
    footnote: beyondPromoFootnotes?.beyond12MonthsHalfPrice,
    promoOptions: {
      type: 'FIRSTMONTHSDISCOUNT',
      duration: 12,
      discountPercentage: 50,
      codes: [Promo.BEYOND12HP],
    },
  },
  {
    order: 1,
    validate: () => {
      const isBeyond = store.get(isBeyondAtom);
      const isHomeRecover = store.get(isHomeRecoverAtom);
      const isFlashSale = PromoService.getActiveIndirectPromos().some(
        (promo) =>
          promo.name === PromoName.FlashSaleSixMonthsFree ||
          promo.name === PromoName.BlackFridaySixMonthsFree
      );
      return !isFlashSale && !isBeyond && !isHomeRecover;
    },
    name: PromoName.TargetedOfferFourMonthsFreeAutoTest,
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    endDate: new Date('2023-12-31T23:59:59'),
    footnote: q3FreeMonthsInsuranceFootnotes.q3FreeMonthsInsurance,
    promoOptions: {
      type: 'FIRSTMONTHSFREE',
      duration: 4,
      codes: [...generateSequentialPromocode(Promo.Q4SAVE, 150), Promo.Q424BEYOND],
    },
  },
];
