import { Stack, DateField, CTAButton } from '@ovotech/element';
import { type DateFormat } from '@ovotech/element/dist/components/DateField/DateField';
import { useEffect, useState } from 'react';

export const MockDateInput = () => {
  const [sessionDate, setSessionDate] = useState<string | null>(null);

  useEffect(() => {
    setSessionDate(sessionStorage.getItem('mock-date'));
  }, [setSessionDate]);

  const getMockDateFormatted = (): DateFormat => {
    const date = sessionDate ? new Date(sessionDate) : new Date();

    return {
      day: date.getDate().toString(),
      month: (date.getMonth() + 1).toString(),
      year: date.getFullYear().toString(),
    };
  };

  const [mockDate, setMockDate] = useState<DateFormat>(getMockDateFormatted());

  const submitMockDate = () => {
    let date = new Date(Number(mockDate.year), Number(mockDate.month) - 1, Number(mockDate.day));

    const timezoneOffset = date.getTimezoneOffset() * 60 * 1000;
    date = new Date(date.getTime() - timezoneOffset);

    sessionStorage.setItem('mock-date', date.toISOString());
    window.location.reload();
  };

  const resetMockDate = () => {
    sessionStorage.removeItem('mock-date');
    setMockDate(getMockDateFormatted());
    window.location.reload();
  };

  return (
    <Stack spaceBetween={4}>
      <DateField
        onChange={(date) => {
          setMockDate(date);
        }}
        id="mock-date-field"
        label=""
        value={mockDate}
      />
      <div>
        <CTAButton onClick={submitMockDate}>Submit</CTAButton>
      </div>
      <div>
        <CTAButton variant="secondary" onClick={resetMockDate}>
          Reset
        </CTAButton>
      </div>
    </Stack>
  );
};
