import { Heading2, P, Tab, TabList, TabPanel, Tabs } from '@ovotech/element';

import { MockDateInput } from './MockDate';

import { OffersCalendar } from '@components/DevOverlay/OffersCalendar';

const DevOverlayModalContent = () => {
  return (
    <div>
      <Heading2>Dev Overlay</Heading2>
      <Tabs fullWidth={true}>
        <TabList>
          <Tab>Mock Date</Tab>
          <Tab>Offers Calendar</Tab>
        </TabList>
        <TabPanel>
          <P>This date will be used when computing active offers</P>
          <MockDateInput />
        </TabPanel>
        <TabPanel>
          <OffersCalendar />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default DevOverlayModalContent;
