import { BOILER_COVER_REWARD_TERMS_FIXED } from '@constants/OVOUrls';
import boilerCoverRewardTerms from '@resources/energy-boiler-cover-reward-terms.pdf';
import { creditTypeAtom, store } from '@src/store/store';

export const getEnergyCreditTermsURL = () => {
  const creditType = store.get(creditTypeAtom);

  if (creditType === 'FIXED') {
    return BOILER_COVER_REWARD_TERMS_FIXED;
  }

  return boilerCoverRewardTerms;
};

export const getEnergyCreditEligiblePlan = () => {
  const creditType = store.get(creditTypeAtom);

  if (creditType === 'FIXED') {
    return 'fixed plans';
  }

  return 'variable plan';
};
